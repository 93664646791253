import React, { useEffect, useRef, useState, useMemo } from "react";
import "./Appdev.scss";
import Divider from "./Divider";
import { ReactComponent as Globe } from "../../media/globe.svg";
import Slider from "react-slick";
import appslider1 from "../../media/appsSlider/slider1.png";
import appslider2 from "../../media/appsSlider/slider2.png";
import appslider3 from "../../media/appsSlider/slider3.png";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
const Appdev = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [button1Active, setButton1Active] = useState(true);
  // const [button2Active, setButton2Active] = useState(false);
  // const [button3Active, setButton3Active] = useState(false);

  // const handleButton1Click = () => {
  //   setButton1Active(true);
  //   setButton2Active(false);
  //   setButton3Active(false);
  // };

  // const handleButton2Click = () => {
  //   setButton1Active(false);
  //   setButton2Active(true);
  //   setButton3Active(false);
  // };
  // const handleButton3Click = () => {
  //   setButton3Active(true);
  //   setButton1Active(false);
  //   setButton2Active(false);
  // };
  const [sliderEnabled, setSliderEnabled] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      // Adjust the breakpoint as needed
      setSliderEnabled(width <= 768); // Disable for tablet and desktop (width > 768)
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slideritem = [
    {
      title: "WEB APPS YANG MELESAT!",
      subtitle:
        "Bingung cara bikin website yang menarik? Ayo, kolaborasi sama kami dan wujudkan ide-ide gila kamu jadi kenyataan! Hubungi kami sekarang!",
      buttontext: "Web Apps",
      text: "Bingung cara bikin website yang menarik? Ayo, kolaborasi sama kami dan wujudkan ide-ide gila kamu jadi kenyataan! Hubungi kami sekarang!",
      img: appslider1,
    },
    {
      title: "MOBILE APPS YANG MEMIKAT!",
      subtitle:
        "Genggam peluang dengan aplikasi mobile seru! Kolaborasi bareng kami untuk wujudkan ide-ide kreatif kamu dan bikin pengguna jatuh cinta! Hubungi kami sekarang!",
      buttontext: "Mobile Apps",
      text: "Genggam peluang dengan aplikasi mobile seru! Kolaborasi bareng kami untuk wujudkan ide-ide kreatif kamu dan bikin pengguna jatuh cinta! Hubungi kami sekarang!",
      img: appslider2,
    },
    {
      title: "APLIKASI DESKTOP YANG EFISIEN!",
      subtitle:
        "Genggam peluang dengan aplikasi mobile seru! Kolaborasi bareng kami untuk wujudkan ide-ide kreatif kamu dan bikin pengguna jatuh cinta! Hubungi kami sekarang!",
      buttontext: "Desktop Apps",
      text: "Genggam peluang dengan aplikasi mobile seru! Kolaborasi bareng kami untuk wujudkan ide-ide kreatif kamu dan bikin pengguna jatuh cinta! Hubungi kami sekarang!",
      img: appslider3,
    },
  ];
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (newIndex) => {
      setCurrentSlide(newIndex);
    },
  };
  const button1Active = useMemo(() => currentSlide === 0, [currentSlide]);
  const button2Active = useMemo(() => currentSlide === 1, [currentSlide]);
  const button3Active = useMemo(() => currentSlide === 2, [currentSlide]);

  function goToSlide1() {
    slider.current.slickGoTo(0); // Go to slide 1 (index 0)
  }

  function goToSlide2() {
    slider.current.slickGoTo(1); // Go to slide 2 (index 1)
  }

  function goToSlide3() {
    slider.current.slickGoTo(2); // Go to slide 3 (index 2)
  }

  const slider = useRef(null);
  return (
    <div className="appdevcont">
      <Divider />
      <div className="center">
        <div className="herocenterhead" id="appsdevelopment">
          <div className="lefthead">
            <div className="wrapper">
              <span>Craxion Studio</span>
              <span>||</span>
            </div>
          </div>
          <div className="righthead">
            <div className="wrapper">
              <span>Layanan</span>
              <span>03</span>
            </div>
          </div>
        </div>
        <div className="webdevtitle">
          WUJUDKAN APLIKASI CIAMIK YANG BIKIN BISNISMU MELEJIT!
        </div>
        <div className="webdevsub">
          Siap untuk jadi bintang di dunia digital? Tim kami bakal bantu kamu
          bikin aplikasi custom yang nggak hanya kece, tapi juga super
          user-friendly! Ayo, bawa interaksi dengan pelangganmu ke level yang
          lebih seru dan tak terlupakan!
        </div>
        <div className="buttonslider">
          <div className="buttoncontainer">
            <button
              className={`garisbutton ${button1Active ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(0);
                goToSlide1();
              }}
            ></button>
            <button
              className={`buttonapp ${button1Active ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(0);
                goToSlide1();
              }}
            >
              <Globe /> &nbsp;Web Apps
            </button>
            <div className="buttontext">
              Buat website yang interaktif dan responsif dengan aplikasi web
              custom kami, siap untuk meningkatkan visibilitas dan interaksi
              bisnismu!
            </div>
          </div>
          <div className="buttoncontainer">
            <button
              className={`garisbutton ${button2Active ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(1);
                goToSlide2();
              }}
            ></button>
            <button
              className={`buttonapp ${button2Active ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(1);
                goToSlide2();
              }}
            >
              <Globe /> &nbsp;Mobile Apps
            </button>
            <div className="buttontext">
              Kembangkan aplikasi mobile yang menarik dan mudah digunakan, untuk
              menjangkau pelanggan di genggaman tangan mereka dan meningkatkan
              loyalitas!
            </div>
          </div>
          <div className="buttoncontainer">
            <button
              className={`garisbutton ${button3Active ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(2);
                goToSlide3();
              }}
            ></button>
            <button
              className={`buttonapp ${button3Active ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(2);
                goToSlide3();
              }}
            >
              <Globe /> &nbsp;Desktop Apps
            </button>
            <div className="buttontext">
              Rancang aplikasi desktop yang powerful dan intuitif, untuk
              memberikan pengalaman pengguna terbaik dan mendukung produktivitas
              bisnismu!
            </div>
          </div>
        </div>
      </div>
      <div className="slidercenter">
        <div className="sliderapp">
          <Slider {...settings} ref={slider}>
            {slideritem.map((slide, index) => (
              <div>
                <div className="mobilebutton">
                  <button className="buttonapp active">
                    <Globe /> &nbsp;{slide.buttontext}
                  </button>
                  <div className="buttontext">{slide.text}</div>
                </div>
                <div className="slideritem" key={index}>
                  <div className="slidercontent">
                    <div className="title">{slide.title}</div>
                    <div className="subtitle">{slide.subtitle}</div>
                    <button
                      onClick={() =>
                        window.open(
                          "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20pembuatan%20aplikasi%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                          "_blank"
                        )
                      }
                      className="buttonwa"
                    >
                      Konsultasikan Sekarang
                      <div>
                        <Arrowbtn
                          width={18}
                          height={20}
                          fill="#001827"
                          className="primary-arrow"
                        />
                        <Arrowbtn
                          width={18}
                          height={20}
                          fill="#001827"
                          className="secondary-arrow"
                        />
                      </div>
                    </button>
                  </div>
                  <div className="sliderimage">
                    <img
                      src={slide.img}
                      className="imgsl"
                      alt="craxion.slider"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Appdev;

import React, { useRef } from "react";
import "./Testimoni.scss";
import handsimg from "../../media/testimoni/raising-hands-emoji.png";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
import Slider from "react-slick";

const Testimoni = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slidesData = [
    {
      testimoni:
        "Craxion Studio telah menghidupkan visi kami melalui desain website yang tidak hanya estetik tetapi juga ramah pengguna. Setiap elemen mencerminkan esensi Lovanastory sebagai solusi kecantikan yang inovatif. Terima kasih atas profesionalisme dan kreativitas Anda yang luar biasa!",
      person: "Lovanastory",
      title: "",
    },
    {
      testimoni:
        "Web Matalokal Indonesia yang dirancang oleh Craxion Studio benar-benar menangkap semangat kreatif dan budaya lokal. Desainnya yang dinamis dan fungsional membuat produk artisan kami bersinar. Kami sangat menghargai dedikasi dan inovasi Anda!",
      person: "Matalokal Indonesia",
      title: "",
    },
    {
      testimoni:
        "Craxion Studio telah membangun website yang mencerminkan komitmen kami terhadap kualitas dan keragaman dalam produk perawatan tubuh. Desainnya yang elegan dan intuitif meningkatkan kehadiran digital kami secara signifikan. Terima kasih atas kerja keras dan ide-ide brilian Anda!",
      person: "PT. Inticosmetic Lestari",
      title: "",
    },
    {
      testimoni:
        "Kami sangat terkesan dengan hasil kerja Craxion Studio yang berhasil merancang website Pai Umroh dengan tampilan yang memukau. Website ini tidak hanya informatif tetapi juga memudahkan pelanggan dalam merencanakan perjalanan ibadah mereka. Terima kasih atas dedikasi dan inovasi Anda!",
      person: "Paipai Travel",
      title: "",
    },
    {
      testimoni:
        "Craxion Studio telah membawa Paipai Travel ke level berikutnya dengan desain website yang modern dan mengundang. Fungsionalitas dan estetika yang ditawarkan benar-benar meningkatkan pengalaman pengguna. Kami sangat menghargai kerja keras dan kreativitas Anda dalam proyek ini!",
      person: "Pai Umroh",
      title: "",
    },
  ];
  const sliderRefs = useRef([]);

  // Function to add refs
  const addToRefs = (el) => {
    if (el && !sliderRefs.current.includes(el)) {
      sliderRefs.current.push(el);
    }
  };
  const next = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickNext();
    });
  };

  // Function to navigate to the previous slide in all sliders
  const previous = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickPrev();
    });
  };
  return (
    <section className="testimoni-section">
      <div className="label">
        <img src={handsimg} alt="compass" />
        Kata Mereka Tentang Craxion
      </div>
      <div className="titlecenter">
        <h4 className="title">Feedback Keren dari Klien Kami</h4>
      </div>
      <div className="slider">
        <div className="arrowspace" onClick={previous}>
          <div className="left-arrow">
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="inner-left-arrow
              "
            />
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="outer-left-arrow
              "
            />
          </div>
        </div>

        <div className="slider2">
          <Slider ref={addToRefs} {...settings}>
            {slidesData.map((slide, index) => (
              <div className="slidback">
                <div className="sliderspacing">
                  <div className="slidertext">
                    <div className="testimoni">{slide.testimoni}</div>
                    <div className="footertestimoni">
                      <div className="person">
                        <div className="personname">{slide.person}</div>
                        <div className="persontitle">{slide.title}</div>
                      </div>
                      <div className="logo"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="arrowspace" onClick={next}>
          <div className="right-arrow">
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="inner-right-arrow
              "
            />
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="outer-right-arrow
              "
            />
          </div>
        </div>
      </div>
      <div className="buttonmob">
        <div className="arrowspace" onClick={previous}>
          <div className="left-arrow">
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="inner-left-arrow
              "
            />
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="outer-left-arrow
              "
            />
          </div>
        </div>
        <div className="arrowspace" onClick={next}>
          <div className="right-arrow">
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="inner-right-arrow
              "
            />
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="outer-right-arrow
              "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimoni;

import React, { useState } from "react";
import "../navbar/Navbar.scss";
import logo from "../../media/logohd.webp";
import { ReactComponent as Arrowbtn } from "../../media/arrow.svg";
import { menuItems } from "../../component/sitemap-data.js";
import { ReactComponent as Plus } from "../../media/Vector.svg";
import { NavHashLink } from "react-router-hash-link";
const Navbar = () => {
  const [currentPath] = useState(window.location.pathname);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className="navbar-container">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="craxion" width="128px" />
          </a>
          <div className="logo-desc">
            Bikin proyek keren dan beragam buat semua level ambisi
          </div>
        </div>
        <ul className={isOpen ? "nav-links active" : "nav-links"}>
          <div className="nav-menu">
            {menuItems.map((item, index) => (
              <li key={index}>
                <NavHashLink
                  onClick={toggleMenu}
                  activeClassName="nav-item-active"
                  to={item.link}
                  smooth
                >
                  <div
                    className={
                      "nav-item" +
                      (currentPath === item.link ? " nav-item-active" : "")
                    }
                  >
                    <div className="inner-item">{item.name}</div>
                  </div>
                </NavHashLink>
              </li>
            ))}
            <div
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20website%20dan%20brand+activation%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                  "_blank"
                )
              }
              className="contact-btn"
            >
              <button className="inner-btn">
                Hubungi Kami{" "}
                <Arrowbtn width={23} height={23} className="arrow" />
              </button>
              <button className="outer-btn">
                Hubungi Kami <Arrowbtn width={23} height={23} fill="#ffffff" />
              </button>
            </div>
          </div>
        </ul>

        <button className="menu-icon" onClick={toggleMenu}>
          <i>Menu &nbsp;</i>
          <Plus className={isOpen ? "minus" : "plus"} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;

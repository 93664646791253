import React from "react";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
import "./Hero.scss";
import waving from "../../media/welcomehome/waving-hand-emoji.png";

const Hero = () => {
  return (
    <section className="head-container">
      <div className="welcome-container">
        <div className="label">
          <img src={waving} alt="waving" />
          <div id="waving"></div>
        </div>
        <h2 className="headline">DARI KHAYALAN KE REALITAS DIGITAL</h2>
        <p className="description">
          Yuk, bikin gebrakan digital bareng! Dari pengembangan web, aplikasi
          kece, sampai manajemen media sosial yang bikin melongo. Kreativitas
          kita ketemu teknologi, siap-siap bikin dunia melirik! Hubungi kami dan
          wujudkan ide gilamu!
        </p>
        <div className="buttondiv">
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20website%20dan%20brand+activation%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                "_blank"
              )
            }
            className="btn-welcome"
          >
            Cari Tahu Sekarang
            <div>
              <Arrowbtn
                width={18}
                height={20}
                fill="#001827"
                className="primary-arrow"
              />
              <Arrowbtn
                width={18}
                height={20}
                fill="#001827"
                className="secondary-arrow"
              />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;

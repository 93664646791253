import React, { useRef } from "react";
import "./Sosmed.scss";
import { ReactComponent as Checklist } from "../../media/check-list.svg";
import star from "../../media/workshowcase/glowing-star-emoji.png";
import intipesonalogo from "../../media/portfolio/logo intipesona.png";
import catalog from "../../media/portfolio/catalog-ntipesona.webp";
import manlogo from "../../media/portfolio/manlogo.svg";
import mancatalog from "../../media/portfolio/mancatalog.png";
import rjcatalog from "../../media/portfolio/rjcatalog.png";
import proflightfav from "../../media/portfolio/proflight-fav.png";
import proflightlogo from "../../media/portfolio/proflight-logo.png";
import mockupphone from "../../media/portfolio/mockup-proflight.svg";
import mockuploony from "../../media/portfolio/mockup-loony.svg";
import mockuprj from "../../media/portfolio/mockup-rj.svg";
import mabowllogoround from "../../media/portfolio/mabowl-logo-round.png";
import mabowltext from "../../media/portfolio/mabowl-text.png";
import mockupmabowl from "../../media/portfolio/mockup-mabowl.svg";
import rjlogoround from "../../media/portfolio/rj-logo-round.png";
import rjtext from "../../media/portfolio/rjtext.svg";
import loonylogoround from "../../media/portfolio/loonylogoround.png";
import loonytext from "../../media/portfolio/loonytext.png";
import onomatext from "../../media/portfolio/onomatext.png";
import onomalogoround from "../../media/portfolio/onomalogoround.png";
import mockuponoma from "../../media/portfolio/mockup-onoma.svg";
import mockuplinktown from "../../media/portfolio/mockup-linktown.svg";
import linktownlogoround from "../../media/portfolio/linktown-logo-round.png";
import linktowntext from "../../media/portfolio/linktowntext.png";
import { ReactComponent as Like } from "../../media/portfolio/like.svg";
import { ReactComponent as Comment } from "../../media/portfolio/comment.svg";
import { ReactComponent as Share } from "../../media/portfolio/share.svg";
import { ReactComponent as Save } from "../../media/portfolio/save.svg";
import Divider from "./Divider";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
import Slider from "react-slick";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
const itempoint = [
  "Konten Viral Menarik",
  "Desain Grafis Kekinian",
  "Strategi Interaktif",
  "Pengalaman Berkesan",
  "Support Tim Kreatif",
];
const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const Sosmed = () => {
  const sliderRef = useRef(null);

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className="sosmedcont">
      <Divider />
      <div className="center">
        <div className="herocenterhead" id="brandactivation">
          <div className="lefthead">
            <div className="wrapper">
              <span>Craxion Studio</span>
              <span>||</span>
            </div>
          </div>
          <div className="righthead">
            <div className="wrapper">
              <span>Layanan</span>
              <span>02</span>
            </div>
          </div>
        </div>
        <div className="webdevtitle">
          GUNCANG DUNIA DIGITAL DENGAN AKTIVASI BRAND YANG BIKIN SEMUA
          NGOMONGIN!
        </div>
        <div className="webdevsub">
          Pilih Paket Aktivasi Seru Kami Mulai dari 2JUTA-AN! Dapatkan konten
          medsos yang catchy, desain grafis yang bikin semua melongo, dan
          strategi yang out of the box. Yuk, jangan sampai ketinggalan! Hubungi
          kami dan jadikan brand kamu bintang di dunia digital!
        </div>
        <div className="pricebox">
          <div className="boxdes">
            <div className="boxtitle">
              Bawa Brand Kamu ke Puncak Kepopuleran!
            </div>
            <div className="boxsub">
              Harga Gila, Kualitas Nggak Ada Lawan! Dapatkan konten medsos yang
              bikin semua melongo dan desain grafis yang out of the box.
            </div>
          </div>
          <div className="boxpoint">
            <div className="pointtitle">Apa yang Anda dapatkan:</div>
            {itempoint.map((item, index) => (
              <div className="point" key={index}>
                <Checklist fill="#000000" />
                &nbsp;{item}
              </div>
            ))}
          </div>
          <div className="boxprice">
            <div className="pricetitle">Mulai dari</div>
            <div className="price">
              Rp
              <span>2.000.000</span>
            </div>
            <div className="pricebutton">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20paket%20brand%20activation%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                    "_blank"
                  )
                }
                className="buttonwa"
              >
                Pilih Paket Ini
                <div>
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="primary-arrow"
                  />
                  <Arrowbtn
                    width={18}
                    height={20}
                    fill="#001827"
                    className="secondary-arrow"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="head-sosmed">
        <div className="headleft">
          <div className="label">
            <img src={star} alt="star" className="star" />
            Karya Terkece Kita Part 1
          </div>
        </div>
        <div className="headright">
          <h4 className="title">KOLEKSI KARYA KITA</h4>
        </div>
      </div>
      <div className="showcase-content">
        <div className="showcase-content-head">
          <div className="showcase-content-head-title">
            Cek karya seru dari layanan Web Development kami yang penuh
            kreativitas dan inovasi ini.
          </div>
          <div className="showcase-content-arrow">
            <div className="left-arrow" onClick={handlePrevClick}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="inner-left-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="outer-left-arrow
              "
              />
            </div>
            <div className="right-arrow" onClick={handleNextClick}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="inner-right-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="outer-right-arrow
              "
              />
            </div>
          </div>
        </div>
        <div className="slider">
          <Slider {...settings} ref={sliderRef}>
            <div className="card card-intipesona">
              <div className="card-top">
                <img
                  src={intipesonalogo}
                  alt="inti pesona"
                  className="image-top-physical"
                />
              </div>
              <div className="card-body">
                <img
                  src={catalog}
                  alt="catalog"
                  className="image-body-physical "
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <li>Katalog Produk</li>
                  <li>PT. Inti Pesona Lestari</li>
                </div>
                <div className="card-info-physical-2">
                  <li>12 Feb 2024</li>
                  <li>intipesonalestari.com</li>
                </div>
              </div>
            </div>
            <div className="card card-man">
              <div className="card-top">
                <img src={manlogo} alt="man" className="image-top-physical" />
              </div>
              <div className="card-body">
                <img
                  src={mancatalog}
                  alt="man"
                  className="image-body-physical "
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <li>Lanyard + ID Card</li>
                  <li>MAN Kota Surabaya</li>
                </div>
                <div className="card-info-physical-2">
                  <li>23 Sep 2023</li>
                  <li>Campex Manesa 2024</li>
                </div>
              </div>
            </div>
            <div className="card card-rj">
              <div className="card-top">
                <img
                  src={rjtext}
                  alt="ruang jenuh coffe"
                  className="image-top-physical"
                />
              </div>
              <div className="card-body">
                <img
                  src={rjcatalog}
                  alt="ruang jenuh coffe"
                  className="image-body-physical "
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <li>Logo</li>
                  <li>Ruang Jenuh Coffee</li>
                </div>
                <div className="card-info-physical-2">
                  <li>5 Sep 2022</li>
                  <li>@ruangjenuh_coffee</li>
                </div>
              </div>
            </div>
            <div className="card card-proflight">
              <div className="card-top">
                <img
                  src={proflightfav}
                  alt="proflight"
                  className="image-top-digital"
                />
                <div>
                  <li>proflight_indonesia</li>
                  <li>Proflight Indonesia Official</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li>46,7 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img
                    src={proflightlogo}
                    alt="proflight"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockupphone}
                  alt="phone"
                  className="image-body-digital"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  104
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>2
                </div>
                <div className="card-info-digital">
                  <Share></Share>6
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  1,5K
                </div>
              </div>
            </div>
            <div className="card card-mabowl">
              <div className="card-top">
                <img
                  src={mabowllogoround}
                  alt="mabowl"
                  className="image-top-digital"
                />
                <div>
                  <li>mabowll</li>
                  <li>MaBowl | Japanese Contemporary Ricebowl</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li>3,39 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img
                    src={mabowltext}
                    alt="mabowl"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockupmabowl}
                  alt="mabowl"
                  className="image-body-digital"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  55
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  24
                </div>
                <div className="card-info-digital">
                  <Share></Share>
                  33
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  700
                </div>
              </div>
            </div>
            <div className="card card-rj">
              <div className="card-top">
                <img
                  src={rjlogoround}
                  alt="logo rj"
                  className="image-top-digital"
                />
                <div>
                  <li>ruangjenuh_coffee</li>
                  <li>Ruang Jenuh</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li>3,05 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img
                    src={rjtext}
                    alt="ruang jenuh coffe"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockuprj}
                  alt="ruang jenuh coffe"
                  className="image-body-digital"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  79
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  25
                </div>
                <div className="card-info-digital">
                  <Share></Share>
                  29
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  550
                </div>
              </div>
            </div>
            <div className="card card-loony">
              <div className="card-top">
                <img
                  src={loonylogoround}
                  alt="loony"
                  className="image-top-digital"
                />
                <div>
                  <li>loonyindonesia</li>
                  <li>LOONY | Celebrate Creativity</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li>42,9 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img
                    src={loonytext}
                    alt="loony"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockuploony}
                  alt="loony"
                  className="image-body-digital"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  258
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  15
                </div>
                <div className="card-info-digital">
                  <Share></Share>6
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  85
                </div>
              </div>
            </div>
            <div className="card card-onoma">
              <div className="card-top">
                <img
                  src={onomalogoround}
                  alt="onoma"
                  className="image-top-digital"
                />
                <div>
                  <li>onomaofficial</li>
                  <li>Onoma Odyssey</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li> 3,43 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img
                    src={onomatext}
                    alt="onoma"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockuponoma}
                  alt="onoma"
                  className="image-body-digital"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  54
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>5
                </div>
                <div className="card-info-digital">
                  <Share></Share>3
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  96
                </div>
              </div>
            </div>
            <div className="card card-linktown">
              <div className="card-top">
                <img
                  src={linktownlogoround}
                  alt="linktown"
                  className="image-top-digital"
                />
                <div>
                  <li>linktown.co.id</li>
                  <li>#1 Rumah Linktown</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li> 52,9 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img
                    src={linktowntext}
                    alt="linktown"
                    className="image-body-logo"
                  />
                </div>
                <img
                  src={mockuplinktown}
                  alt="linktown"
                  className="image-body-digital"
                />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  345
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>39
                </div>
                <div className="card-info-digital">
                  <Share></Share>52
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  1,5K
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Sosmed;

import React from "react";
import Marquee from "react-fast-marquee";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
import "./Divider.scss";
const Divider = () => {
  const serviceHeadline = ["LAYANAN KAMI"];
  const serviceName = ["BRAND ACTIVATION"];
  return (
    <div className="running-text-sosmed">
      <div className="header-running">
        <Marquee autoFill="true" speed={100}>
          {serviceHeadline.map((item, index) => (
            <div key={index}>
              <h2>{item}</h2>
              <ArrowSlider
                width={65}
                height={65}
                fill="#FDFAFF"
                className="arrow-headline"
              />
            </div>
          ))}
        </Marquee>
      </div>
      <Marquee autoFill="true" direction="right" speed={100}>
        {serviceName.map((item, index) => (
          <div className="service-running" key={index}>
            <h4>{item}</h4>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default Divider;

import React from "react";
import "./About.scss";
import lovana from "../../media/companies/lovana.png";
import inticosmetic from "../../media/companies/inticosmetic.png";
import intipesona from "../../media/companies/intipesona.png";
import maipark from "../../media/companies/maipark.png";
import nextion from "../../media/companies/nextion.png";
import paipaitravel from "../../media/companies/paipaitravel.png";
import yoska from "../../media/companies/yoska.png";
import Marquee from "react-fast-marquee";

const About = () => {
  return (
    <section className="aboutcontainer">
      <div className="center">
        <div className="abouttitle">UBAH IDE JADI KENYATAAN BERSAMA KAMI!</div>
        <div className="aboutdes">
          Di Craxion Studio, kami bukan hanya mendesain, kami menghidupkan
          ide-ide kamu dengan semangat dan kreativitas tanpa batas! Ayo, gabung
          bersama kami dan bawa perubahan ke dunia digital.
          <br /> Bersama klien-klien hebat, kita akan menciptakan cerita sukses
          yang tak terlupakan. Siap untuk membuat gebrakan bareng?
        </div>
        <div className="aboutportof">
          <Marquee autoFill="true" speed={100}>
            <div className="brands">
              <div className="brands-slide">
                <img src={yoska} alt="yoska" />
                <img src={maipark} alt="maipark" />
                <img src={nextion} alt="nextion" />
                <img src={paipaitravel} alt="paipaitravel" />
                <img src={inticosmetic} alt="inticosmetic" />
                <img src={intipesona} alt="intipesona" />
                <img src={lovana} alt="lovana" />
              </div>
            </div>
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default About;
